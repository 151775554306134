.container {
  text-align: center;
}

.month-selector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.month-card {
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s, color 0.2s;
}

.month-card:hover {
  transform: scale(1.05);
}

.month-card.selected {
  background-color: #fd8f82;
  color: white;
}

.dates-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.date-card {
  margin: 5px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

h1,
h2 {
  color: #333;
}

button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.time-slot {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.time-slot.disabled {
  background-color: #fd8f82;
  color: #ffffff;
  cursor: not-allowed;
  text-decoration: line-through;
}

.time-slot:hover {
  background-color: #e0e0e0;
}

.details-list-card {
  padding: 1rem;
  margin: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details-list-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-list-top-left {
  display: flex;
  align-items: start;
}
.details-list-table {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.dropdown-container {
  display: flex;
}

.months-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.month-button {
  background: none;
  border: none;
  cursor: pointer;
}

.month-card {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.selected-month {
  margin-top: 1rem;
}
.second-dropdown {
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .details-list-table {
    display: flex;
    flex-direction: column;
  }
  .dropdown-container {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .second-dropdown {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .details-list-card {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .details-list-top-left-title {
    font-size: 1.25rem;
  }

  .dropdown {
    flex: 1 1 100%;
  }

  .months-container {
    justify-content: center;
  }
}
