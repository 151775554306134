@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.service-container {
  width: 70vw;
}

.service-card {
  background-color: #fd8f82;
}
.sc-img-container {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.card-class {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: auto;
  margin: auto;
  background-color: $background-color;
  border-radius: 10px;
  padding: 10px;
}
.card-class:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}
.cl-content-details-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cl-content-details-left-side {
  margin: 0.3rem 0 0.3rem 0;
}
.cl-content-details-left-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: $primary-color;
  margin-left: 20px;
}
.cl-content-details-left-side-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 130px;
}
.cl-content-details-left-side-dot {
  margin-right: 10px;
}
.cl-content-details-left-side-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  width: 250px;
}
.cl-content-details-hr {
  height: auto;
  border: 1px solid $black-color;
}
.cl-content-details-right-doc {
  margin-left: 10px;
}
.franchise-details-logo {
  height: 130px;
  width: 130px;
}
.info-view-btn,
.info-edit-btn,
.info-sub-btn,
.inactive-btn,
.info-reset-btn,
.info-delete-btn {
  border: none;
  color: $text-color;
  height: 40px;
  border-radius: 10px;
  width: 100px;
  margin: 0.5rem;
  font-size: 14px;
}

.info-view-btn {
  background-color: $--success-color;
}

.info-edit-btn {
  background-color: $--edit-color;
}

.info-sub-btn {
  background-color: $--primary-color;
  width: 160px;
}

.info-reset-btn {
  background-color: $--primary-color;
}

.inactive-btn {
  background-color: $accent-color;
}

.info-delete-btn {
  background-color: $--error-color;
}

.ser-selected-text {
  padding-left: 10px;
}

.ser-add-btn-sub {
  font-weight: 500;
  font-size: 14px;
  color: $text-color;
  background: $primary-color;
  border: $primary-color;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  transition: box-shadow 0.33s ease-in-out;
  margin-top: 10px;
  height: 3rem;
}
.ser-add-btn-sub:hover {
  outline: none;
  box-shadow: 3px 3px 15px 3px $primary-dark-color;
}

.content-top-right-btn-close {
  background-color: transparent;
  border: none;
  float: right;
  border-radius: 5px;
  margin: 10px 1rem 0 0;
  height: 45px;
  padding: 10px;
}
.content-top-right-btn-close:focus {
  outline: none;
}
.timeslots-container {
  background-color: #f0f0f0;
}

.content-top-right-btn-close-icon {
  height: 7px;
  width: 7px;
}

.time-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.time-slot {
  padding: 5px 10px;
  // margin: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sub-box-main {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.sub-box {
  position: relative;
  width: auto;
  margin: 5rem 15rem 10rem 15rem;
  height: auto;
  background: $text-color;
  border-radius: 4px;
  border: 1px solid #333333;
  overflow: auto;
  padding: 20px;
}
.service-drop-div {
  margin-top: -20px;
}
.country-main-div {
  margin-top: 20px;
}
.service-pt-one,
.service-pt-two,
.service-pt-three {
  flex: 1;
  max-width: 50%;
  padding: 0.5rem;
}

.radio-input-div {
  margin: 0 20px 0 20px;
}
.radio-label {
  padding: 0 0 0 5px;
  font-size: 16px;
}
@media (max-width: 500px) {
  .service-drop-div {
    margin-top: 10px;
  }
}


// new-styles
.service-search-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.service-grid-card-container {
  padding: 1rem;
  border: 1px solid #fd8f82;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}
.service-grid-card-container:hover {
  box-shadow: #fd8f8250 0px 48px 100px 0px;
  box-shadow: #fd8f8210 0px 20px 25px -5px, #fd8f8250 0px 10px 10px -5px;
}
.service-search-bar-container {
  display: flex;
}
.service-search-input-container {
    display: flex;
    align-items: center;
    width: 230px;
    height: 40px;
    background: $text-color;
    border: 1px solid $dark-gray-color;
    border-radius: 5px;
    box-shadow: 0px 10px 60px rgba(79, 79, 79, 0.2);
}
.service-icon-img {
  width: 100%;
  margin-bottom:1rem;
}
.service-card-three-dots{
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #ffffff;
  padding: 2px;
  border-radius: 100px;
  cursor: pointer;
}
.service-name-title-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.service-name-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $primary-color;
}
.three-dots-menu {
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  right: -100px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}


.details-card-container {
  display: flex;
  background-color: #FFC3BC;
  border: 1px solid #fd8f82;
  box-shadow: #fd8f82 0px 25px 20px -20px;
  padding: 10px;
  border-radius: 20px;
  justify-content: space-between;
}
.details-icon-container {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  background-color: #ffffff;
}
.details-card-icon {
  height: 100%;
  width: 100%;
}
.details-card-info-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 15px;
}
.details-card-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.details-cart-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #333F4C;
}
.details-card-status {
  border-radius: 5px;
  padding: 0px 15px;
  margin-left: 15px;
  cursor: pointer;
}
.status-active {
  background-color: $--success-color;
}
.status-inactive {
  background-color: $--error-color;
}
.details-card-status-text {
  color: #ffffff;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
}
.details-card-service-key-text {
  color: #333F4C;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
}
.details-card-property-type-text {
  margin-top: 5px;
  color: #333F4C;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  text-decoration-line: underline;
}
.details-timeslot-card-container {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 5px 10px;
  border: 1px solid #fd8f82;
  border-radius: 10px;
  margin-right: 15px;
}
.details-timeslot-card-title {
  color: #fd8f82;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
}
.details-timeslot-card-value {
  color: #fd8f82;
  font-size: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
}
.details-card-crud-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.details-icon-btn-container {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 12px 12px;
  border: 1px solid #fd8f82;
  border-radius: 10px;
  margin: 0 5px 0 0;
}
.details-card-add-btn {
  border: 1px solid #fd8f82;
  color: #fd8f82;
  font-weight: 600;
  border-radius: 10px;
  padding: 10px 10px;
  font-size: 14px;
  background-color: #FFFFFF;
}
.property-accordion-title-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.property-title-container {}
.property-accordion-btn-container {
  display: flex;
}