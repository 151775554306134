@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.ac-inputText-input {
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.ac-inputText-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ac-inputText-input-container {
  border: 1px solid $border-color;
  height: 35px;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 20px;
  width: 100%;
  border-radius: 4px;
}

.ac-inputText-label {
  font-size: 15px;
  margin-bottom: 0;
}

.dropdown-container {
  // width: 300px;
  flex: 1;
}
.dropdown-input {
  flex: 1
}
.drop-label {
  font-size: 15px;
  align-self: flex-start;
  margin-bottom: 0;
}