@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.deleteCustomer-box-main {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.deleteCustomer-box {
  position: relative;
  width: 520px;
  margin: 0 auto;
  height: auto;
  max-height: 90vh;
  margin-top: calc(100vh - 70vh - 20px);
  background: $text-color;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  border-radius: 10px;
}
.del-pop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.del-pop-icon {
  height: 50px;
  width: 50px;
}
.del-pop-text-div {
  display: flex;
  justify-content: center;
}
.del-pop-text {
  font-size: 22px;
  margin-top: 20px;
  text-align: center;
}
.del-pop-btn-yes {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: $error-color;
  color: $text-color;
  border: none;
}
.del-pop-btn-no {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  color: $black-color;
  border: none;
}
@media (max-width: 550px) {
  .deleteCustomer-box {
    width: 400px;
  }
  .del-pop-text {
    font-size: 20px;
  }
  .del-pop-btn-yes,
  .del-pop-btn-no {
    width: 100px;
    height: 40px;
    font-size: 18px;
  }
}

@media (max-width: 430px) {
  .deleteCustomer-box {
    width: 300px;
  }
  .del-pop-text {
    font-size: 15px;
  }
  .del-pop-btn-yes,
  .del-pop-btn-no {
    width: 60px;
    height: 30px;
    font-size: 12px;
  }
}
