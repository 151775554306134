$primary-light-color: #84b7c3;
$primary-color: #fd8f82;
$primary-dark-color: #33b6d645;
$secondary-color: #3e4958;
$background-color: #f2f2f2;
$text-color: #ffffff;
$accent-color: #ccc;
$black-color: #000000;
$error-color: #f70d1a;
$border-color: #e6e8ec;
$light-gray-color: #f7f7f7;
$dark-gray-color: #656565;
$sidebar-hover: #b1645b;
$success-color: #00a743;
$edit-color: #167a93;

$--primary-color: #ff9800;
$--primary-dark-color: #0056b3;
$--success-color: #4caf50;
$--edit-color: #2196f3;
$--accent-color: #17a2b8;
$--error-color: #f44336;
