.inputText-input {
  border: none;
  box-shadow: none;
  --webkit-appearance: none;
  outline: none;
  width: 100%;
  padding-left: 5px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* background-color: rgba(248, 248, 248, 1); */
  margin-left: 10px;
}
.inputText-input-wrapper {
  // margin: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 40px;
}
.inputText-input-container {
  flex-grow: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
  width: 300px;
  height: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #ffffff;
}

.inputText-label {
  left: 1em;
  /* background-color: rgba(248, 248, 248, 1); */
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
}
.inputText-img {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}
.inputText-error-container {
  width: 100%;
  background-color: #ffbbc2;
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inputText-error-container span {
  margin-left: 10px;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
}
.time-picker-input-container {
  // flex-grow: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  // position: relative;
  // width: 200px;
  height: 100%;
  min-height: 40px;
  display: flex;
  background-color: #ffffff;
}

.time-picker-inputText-label {
  left: 1em;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
}

.react-time-picker {
  display: inline-flex;
  position: relative;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-time-picker__inputGroup__input {
  min-width: 1rem;
  height: 100%;
  position: relative;
  padding: 0 10px 0px 10px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-time-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-time-picker__button:enabled {
  cursor: pointer;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
}

.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
}

.react-time-picker__button svg {
  display: inherit;
}

.react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  z-index: 1;
}

.react-time-picker__clock--closed {
  display: none;
}
