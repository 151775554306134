@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.dash-list-card {
  background-color: $text-color;
  align-items: initial;
  margin-bottom: 2rem;
  border-radius: 10px;
  padding: 2rem 2rem 6rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.dashboard-container {
  display: flex;
}
.dash-splash-img-div {
  height: 100%;
  width: 100%;
  border: 1px $border-color solid;
  border-radius: 10px;
  padding: 5rem;
}

.dash-splash-selected-img-div {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px $border-color solid;
  background-size: contain;
}

.dash-splash-selected-img-div:hover .splash-edit {
  display: block;
}

.splash-edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: none;
}

.splash-edit img {
  height: 50px;
  width: 50px;
}

@media (max-width: 700px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
  }
  .dashboard-input-btn-div {
    margin-left: -30px;
  }
}
